import React from "react";
 
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
 
import { Gallery, Item } from 'react-photoswipe-gallery'
import './scoped-style/PhotoSwipe.scss'

const options = {
  bgOpacity: 0.5,
  tapToToggleControls: false,
  tapToClose: true
}

class PhotoSwipe extends React.Component {
  
  constructor() {
    super();
    this.state = {
    }
  }

  render() {
    console.log(this.props.photos);
    
    return (
      <div className="photo-gallery">
        <Gallery options={options}>
          <ul className="image-list-box">
            {this.props.photos.map(( item, index) => (
              <li className="image-list-item" key={item.node.frontmatter.featuredimage.id}>
                <Item
                  original={item.node.frontmatter.featuredimage.childImageSharp.fluid.src}
                  thumbnail={item.node.frontmatter.featuredimage.childImageSharp.fluid.src}
                  width={item.node.frontmatter.featuredimage.childImageSharp.original.width}
                  height={item.node.frontmatter.featuredimage.childImageSharp.original.height}
                >
                  {({ ref, open }) => (
                    <img ref={ref} onClick={open} src={item.node.frontmatter.featuredimage.childImageSharp.fluid.src} />
                  )}
                </Item>
              </li>
            ))}
          </ul>
        </Gallery>
      </div>
      
    )
  }
}

export default PhotoSwipe;