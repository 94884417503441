import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PhotoSwipe from '../components/PhotoSwipe'

import './scoped-style/BlogRoll.scss'
import './scoped-style/BlogSmallRoll.scss'

class GalleryRoll extends React.Component {
  
  constructor() {
    super();
    this.state = {
    }
  }
  
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    
    return (
      <div>
        <PhotoSwipe photos={posts} />
      </div>
      
    )
  }
}

GalleryRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
    query GalleryRollQuery {
      allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "gallery-post"}}}) {
        edges {
          node {
            excerpt(pruneLength: 200)
            id
            fields {
              slug
            }
            frontmatter {
              title
              templateKey
              date(formatString: "YYYY/M/D")
              tags
              featuredpost
              featuredimage {
                id
                childImageSharp {
                  id
                  resize {
                    src
                    width
                    height
                  }
                  fluid(jpegQuality: 100) {
                    src
                    presentationWidth
                    presentationHeight
                  }
                  original {
                    src
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
    
    `}
    render={(data, count) => <GalleryRoll data={data} count={count} />}
  />
)
